* {
  box-sizing: border-box;

  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;

  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
}
