:root {
  --gray10: #f4f4f4;
  --gray20: #e0e0e0;
  --gray30: #c6c6c6;
  --gray40: #a8a8a8;
  --gray50: #8d8d8d;
  --gray60: #6f6f6f;
  --gray70: #525252;
  --gray80: #393939;
  --gray90: #262626;
  --gray95: #161616;

  --primary50: #488cf1;
  --primary60: #3a70c1;
  --primary70: #2b5491;
  --success40: #42be65;
  --success50: #24a148;
  --success60: #198038;
  --urgent40: #ff8389;
  --urgent50: #fa4d56;
  --urgent60: #da1e28;
  --warning40: #fedf89;
  --warning50: #fec84b;
  --warning60: #fdb022;

  --headerHeight: 64px;
  --tutorialBarHeight: 124px;
}

#root {
  background-color: #000000;
  color: var(--gray10);
}

#root.img {
  background-image: url("./assets/cirrus-page-background.svg");
  background-repeat: no-repeat;
}

a:not(:hover):not(.mapboxgl-map a) {
  text-decoration: none;
}

a:not(:hover):not(.mapboxgl-ctrl-attrib a) {
  color: var(--gray10);
}

button,
select {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  padding: 3px 7px;
  border: 1px solid transparent;

  background-color: transparent;
  color: white;
  cursor: pointer;

  white-space: nowrap;
}

button:not(.input-wrapper button):not(.menu > button) {
  border-radius: 4px;
}

button:focus,
select:focus {
  border: 1px solid var(--primary50);
}

main a:not(.mapboxgl-map a):not(.card) {
  padding: 3px 7px;
  border: 1px solid transparent;
  border-radius: 4px;
}

main a:focus:not(.mapboxgl-map a):not(.card) {
  border: 1px solid var(--primary50);
}

main a:hover:not(.mapboxgl-map a) {
  color: var(--gray30);
}

.menu > a {
  height: 24px;

  text-align: left;
}

button.action:focus {
  color: var(--gray10);
}

button.action {
  background-color: var(--primary50);
  color: #000000;

  font-size: 12px;
  font-weight: 900;
}

button.action:hover:not(:disabled) {
  background-color: var(--primary60);
}

button.inline {
  color: var(--gray10);

  font-size: 14px;
  line-height: 16px;
  text-align: left;
}

button.inline:hover:not(.return):not(:disabled) {
  background-color: var(--gray70);
}

button.return:hover {
  background-color: var(--gray90);
}

/* TODO: Clean up this ridiculous selector */
main
  button:hover:not(:disabled):not(.MuiButtonBase-root):not(
    .MuiPickersYear-yearButton
  ):not(.map-control):not(.mapboxgl-ctrl-zoom-in):not(
    .mapboxgl-ctrl-zoom-out
  ):not(.mapboxgl-ctrl-compass):not(.light button):not(button.action):not(
    .return
  ):not(.inline) {
  background-color: var(--gray70);
}

button.md {
  height: 30px;
  padding: 0 23px;

  font-weight: 700;
}

button.lg {
  height: 40px;
  padding: 12px 47px;

  line-height: 14px;
}

button:disabled:not(.MuiButtonBase-root) {
  background-color: var(--gray90);
  color: var(--gray70);
  cursor: not-allowed;
}

.modal {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 900px;
  max-height: calc(100vh - 128px);
  padding: 24px 30px;
  border-radius: 4px;

  background-color: #ffffff;
  color: var(--gray95);

  text-align: center;
}

.modal-body {
  overflow-y: auto;
}

.modal-body > div {
  text-align: left;
}

.modal h2 {
  margin: 18px 0;

  font-size: 34px;
  font-weight: 700;
}

.modal input:not(.MuiInputBase-input) {
  padding: 12px;

  font-size: 14px;
}

.modal select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;

  padding: 12px;
  padding-top: 9px;

  font-size: 14px;
  line-height: 20px;
}

.modal label:not(.MuiFormLabel-root) {
  font-size: 14px;
  font-weight: 600;
}

.modal p {
  color: var(--gray60);

  font-size: 12px;
}

.modal > p {
  margin: 18px 36px;
}

.modal-body
  > div
  span:not(label > span):not(.MuiInputBase-root span):not(
    .service-access-summary span
  ) {
  font-size: 10px;
}

.modal span:not(.urgent):not(.warning):not(.MuiTypography-root) {
  color: var(--gray60);
}

.urgent {
  color: var(--urgent50);
}

.warning {
  color: var(--warning60);
}

header {
  display: flex;
  justify-content: space-between;
  height: var(--headerHeight);
  padding: 12px 16px;
}

.no-img header {
  background-color: var(--gray95);
}

header > *:first-child {
  margin-left: -12px;
  padding: 4px 11px;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 4px;
}

header > *:first-child:focus {
  border: 1px solid var(--primary50);
}

header > *:first-child:hover {
  background-color: var(--gray90);
}

h1 {
  background: linear-gradient(90deg, #488cf1 0.5%, #a178e4 70%, #ab4e85 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 64px;
  font-weight: 700;
  line-height: 76.8px;
}

h2 {
  font-size: 16px;
  font-weight: 800;
  line-height: 19.2px;
}

h3 {
  font-size: 14px;
}

input,
select {
  width: 0;
  border: solid 2px var(--gray80);

  background-color: var(--gray80);
  color: white;
  line-height: 20px;
  resize: none;
}

input[type="number"],
input[type="password"],
input[type="text"]:not(.MuiInputBase-input) {
  height: 40px;
}

input[type="search"]:not(.MuiInputBase-input) {
  height: 40px;
  padding-left: 32px;
}

input:not(.MuiInputBase-input):hover,
select:hover {
  border: solid 2px var(--gray70);

  background-color: var(--gray70);
}

input:disabled:not(.MuiInputBase-input) {
  border: solid 2px var(--gray90);

  background-color: var(--gray90);
  cursor: not-allowed;
  user-select: none;
}

.light button:hover,
.light select:hover {
  background-color: var(--gray20);
}

input.error:not(:focus),
select.error:not(:focus) {
  border: solid 2px var(--urgent50);
}

input.warning:not(:focus),
select.warning:not(:focus) {
  border: solid 2px var(--warning50);
}

input.changed:not(:focus),
select.changed:not(:focus) {
  border: solid 2px var(--success50);
}

.toggle-wrapper.changed input:not(:focus) + .toggle {
  background-color: var(--success50);
}

input:not(.MuiInputBase-input):focus,
select:focus {
  border: solid 2px var(--primary50);
}

.light button:disabled,
.light select:disabled {
  background-color: var(--gray10);
}

input::-webkit-search-cancel-button {
  display: none;
}

select {
  min-width: 200px;
}

.light input:not(.MuiInputBase-input),
.light select {
  border: solid 1px var(--gray20);

  background-color: #ffffff;
  color: var(--gray95);
}

.light input:disabled:not(.MuiInputBase-input),
.light select:disabled {
  background-color: var(--gray20);
  user-select: none;
}

.light input:hover:not(:disabled),
.light select:hover:not(:disabled) {
  background-color: var(--gray20);
}

.light input:not(.MuiInputBase-input).error:not(:disabled):not(:focus),
.light select.error:not(:disabled):not(:focus) {
  border: solid 1px var(--urgent50);
}

.light input:not(.MuiInputBase-input).warning:not(:disabled):not(:focus),
.light select.warning:not(:disabled):not(:focus) {
  border: solid 1px var(--warning50);
}

.light input:not(.MuiInputBase-input).changed:not(:disabled):not(:focus),
.light select.changed:not(:disabled):not(:focus) {
  border: solid 1px var(--success50);
}

.light input:not(.MuiInputBase-input):focus,
.light select:focus {
  border: solid 1px var(--primary50);
}

.radio-wrapper {
  display: flex;
}

.radio-wrapper:focus {
  border: solid 1px var(--primary50);
}

.radio-wrapper > label {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-top: 4px;
  padding: 0 12px;
  border: solid 1px transparent;
  border-radius: 6px;

  cursor: pointer;
}

.radio-wrapper > label:hover {
  color: var(--gray70);
}

.radio-wrapper > label:has(> input:focus) {
  border: solid 1px var(--primary50);
}

.radio-button {
  width: 18px;
  height: 18px;
  border: solid 1px var(--gray20);
  border-radius: 9px;

  background-color: #ffffff;
}

.radio-wrapper input:checked + .radio-button {
  border: solid 5px var(--primary50);
}

.radio-wrapper > label:has(> input.changed:not(:focus)) {
  border: solid 1px var(--success50);
}

.radio-button-label {
  padding-left: 8px;

  font-weight: 600;
}

#layer-picker {
  z-index: 1;
}

#layer-picker > div {
  display: flex;
  align-items: center;
}

#layer-picker > h2 {
  margin-bottom: 10px;

  font-weight: 700;
}

#layer-picker > h2:not(:first-of-type) {
  margin-top: 26px;
}

#layer-picker input {
  width: 18px;
  height: 18px;
  margin: 9px 0;
}

#layer-picker label {
  padding-left: 8px;

  font-size: 14px;
}

#layer-picker input[type="radio"] {
  border-radius: 50%;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - var(--headerHeight));
  overflow: auto;
}

#root.img main {
  display: grid;
  padding-bottom: var(--headerHeight);
}

#alert-box {
  z-index: 4;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;
  left: calc(50% - 240px);
}

.alert {
  width: 480px;
  margin-top: 8px;
  padding: 12px;
  border-radius: 4px;

  opacity: 1;

  transition: opacity 5s;
}

.alert.fading {
  opacity: 0.3;
}

.alert.error {
  background-color: var(--urgent60);
}

.alert.error:hover {
  background-color: var(--urgent50);
}

.alert.info {
  background-color: var(--primary60);
}

.alert.info:hover {
  background-color: var(--primary50);
}

.alert.warning {
  background-color: var(--warning60);
  color: #000000;
}

.alert.warning:hover {
  background-color: var(--warning50);
}

.badge {
  max-width: calc(100% - 16px);
  padding: 2px 8px;
  border-radius: 4px;

  background-color: var(--gray10);
  color: var(--primary70);

  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.breadcrumb {
  cursor: pointer;
  color: var(--gray40);

  font-weight: 700;
}

.breadcrumb:hover {
  color: white;
}

.card {
  padding: 22px;
  border: solid 2px transparent;
  border-radius: 8px;

  background-color: #26262699;
  color: var(--gray10);

  text-decoration: none;
}

.card.focus,
.card:focus {
  padding: 22px;
  border: 2px solid var(--primary50);
}

.card:hover:not(.disabled) {
  background-color: #262626ff;
}

.card h2 {
  background: linear-gradient(90deg, #ff7eb6 0.5%, #be95ff 54.51%);
  background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 24px;
  line-height: 28.8px;
}

.grid {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 64px;
  row-gap: 48px;
}

.grid > .card {
  position: relative;
  display: inline-block;
  width: 364px;
  height: 203px;

  cursor: pointer;
  box-shadow: 0px 10px 15px 0px #1018281a;

  font-size: 14px;
  line-height: 16px;
}

.grid > .card.disabled {
  cursor: not-allowed;
}

.grid > .card > button {
  position: absolute;
  bottom: 24px;
}

.grid > .card > h2 {
  margin-bottom: 24px;
}

.horizontal-line {
  flex: 1;
  height: 1px;

  background-color: var(--gray50);
}

#tutorial-bar .horizontal-line {
  height: 2px;
  margin: 0 16px;

  background-color: var(--gray50);
}

.horizontal-line.finished {
  background-color: var(--primary60);
}

#tutorial-bar .horizontal-line.finished {
  background-color: var(--primary60);
}

.horizontal-separator {
  width: calc(100% - 32px);
  height: 0;
  margin: 8px 16px;
  border-bottom: solid 1px var(--gray60);
}

.input-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  border-radius: 6px;

  overflow: hidden;
}

.input-wrapper > *:only-child {
  border-radius: 6px;
}

.input-wrapper > *:first-child:not(:only-child) {
  border-radius: 6px 0 0 6px;
}

.light
  .input-wrapper
  > *:not(:first-child):not(:only-child):not(:focus):not(:hover):not(input) {
  border-left: 1px solid #ffffff;
}

.input-wrapper > *:last-child:not(:only-child):not(+ input) {
  border-radius: 0 6px 6px 0;
}

.input-wrapper > img + input:last-child {
  border-radius: 6px;
}

.input-wrapper > img + input:not(:last-child) {
  border-radius: 6px 0 0 6px;
}

.input-wrapper > button,
.input-wrapper > select {
  min-width: 40px;
  height: 40px;

  background-color: var(--gray80);
}
.input-wrapper > select {
  width: 100%;
}

.input-wrapper > button:focus {
  border: solid 2px var(--primary50);
}

.input-wrapper > div > button {
  height: 36px;

  background-color: var(--gray80);

  font-size: 12px;
  font-weight: 600;
}

.input-wrapper > div > button:hover:not(.light button) {
  background-color: var(--gray70);
}

.input-wrapper > input {
  flex: 1;
}

.input-wrapper > div {
  top: 0;
  right: 0;
}

.input-wrapper > button:last-child {
  background-color: var(--gray80);
  color: #ffffff;
  border-radius: 0 6px 6px 0;
}

.light .input-wrapper > button,
.light .input-wrapper > select {
  border: solid 1px var(--gray20);

  background-color: #ffffff;
  color: #000000;
}

.light .input-wrapper > button:focus,
.light .input-wrapper > select:focus {
  border: solid 1px var(--primary50);
}

.light .input-wrapper > button:hover {
  background-color: var(--gray20);
}

.input-wrapper > img {
  position: absolute;
  top: 11px;
  left: 9px;

  cursor: pointer;
}

.toggle-wrapper {
  display: flex;
  align-items: center;
  height: 19px;

  cursor: pointer;
}

.toggle {
  display: flex;
  align-items: center;
  width: 28px;
  height: 19px;
  border: solid 2px var(--gray90);
  border-radius: 9.5px;

  background-color: var(--gray30);
}

.toggle-wrapper:hover input:not(:disabled) + .toggle {
  background-color: var(--gray20);
}

.light .toggle {
  border: solid 2px #ffffff;
}

input:checked + .toggle {
  background-color: var(--primary50);
}

input:disabled + .toggle {
  cursor: not-allowed;
}

.modal:not(.light) input:disabled + .toggle {
  background-color: var(--gray80);
}

.toggle-wrapper:hover > input:checked:not(:disabled) + .toggle {
  background-color: var(--primary60);
}

input:focus + .toggle {
  border: solid 2px var(--primary50);
}

.toggle-wrapper:hover > input:focus + .toggle {
  border: solid 2px var(--primary60);
}

.slider {
  width: 15px;
  height: 15px;
  border-radius: 50%;

  background-color: var(--gray10);

  transition: margin-left 0.2s;
}

input:checked + .toggle > .slider {
  margin-left: 9px;
}

.tab-panel-container {
  padding-top: calc(var(--headerHeight) + 12px);
  align-items: stretch;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tab-panel-header {
  align-self: flex-start;
  position: relative;
  height: 40px;
  margin-bottom: -40px;
  display: flex;
  flex-direction: row;
}

.tab-panel-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.tab {
  border: solid 2px var(--gray80);
  border-radius: 6px;
  background-color: var(--gray80);
  padding: 4px 10px;
  margin: 0px 5px;
  font-size: 30px;
  line-height: 30px;
}

.tab > * {
  font-weight: 750;
}

.tab.active {
  background-color: var(--primary50);
  border: solid 2px var(--primary50);
}

.tab:hover.active {
  cursor: default;
}

.tab:hover:not(.active) {
  cursor: pointer;
  border: solid 2px var(--primary50);
}

.menu {
  position: absolute;
  z-index: 3;
  width: 291px;
  top: 64px;
  right: 16px;
  padding: 16px 0;
  border-radius: 4px;

  background-color: var(--gray90);
  cursor: default;
  box-shadow: 0px 10px 15px 0px #1018281a;
}

.menu > * {
  display: block;
  padding: 0 16px;

  color: var(--gray10);

  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
  text-align: left;
}

.menu a:not(:focus) {
  border: 1px solid transparent;
}

.menu a:hover {
  color: var(--gray30);
}

.menu a:focus {
  border: 1px solid var(--primary50);
}

.menu > h3 {
  height: 16px;
  margin-bottom: 4px;

  color: var(--gray30);

  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}

.menu > hr {
  height: 8px;
  padding: 0 16px;
  border-bottom: solid 1px var(--gray60);
}

.menu > button {
  width: 100%;
  height: 24px;
  border-radius: 0;

  font-weight: 400;
}

.menu > button:hover {
  color: var(--gray30);
}

#clickaway {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1;

  background-color: rgba(0, 0, 0, 0.3);
}

#header-items {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-right: -12px;
  padding: 0 12px 0 9px;
  border-radius: 4px;
}

#header-items:has(> *:hover:not(.menu)) {
  background-color: var(--gray90);
}

.map-control {
  background-color: white;
}

.map-control:hover {
  background-color: var(--gray20);
}

.panel {
  display: flex;
  height: 100%;
}

.panel-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  border: 1px solid var(--gray80);
  border-radius: 4px;

  background-color: var(--gray80);
}

.panel-card:not(.inert) {
  cursor: pointer;
}

.panel-card:hover:not(.inert),
.panel-card.hovered:not(.inert) {
  border: 1px solid var(--gray70);

  background-color: var(--gray70);
}

.panel-card:focus,
.panel-card.focused {
  border: 1px solid var(--primary50);

  background-color: #2a323e;
}

.panel-card.compact > div {
  height: 56px;
}

.panel-card.compact > div:first-child {
  height: 32px;
}

.panel-card.compact > div:first-child > div > span {
  top: 8px;
}

.panel-card > div {
  display: flex;
  height: 62px;
}

.panel-card > div:not(:first-child) {
  border-top: 1px solid var(--gray50);
}

.panel-card > div > div {
  position: relative;
  flex: 1;
}

.panel-card > div > div:not(:first-child) {
  border-left: 1px solid var(--gray50);
}

.panel-card > div > div > label {
  position: absolute;
  top: 8px;
  left: 8px;

  color: var(--gray20);

  font-size: 14px;
  line-height: 16px;
}

.panel-card > div > div > span {
  position: absolute;
  bottom: 8px;
  left: 8px;

  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.panel-card > div > div > span:not(.badge, .timestamp) {
  width: calc(100% - 16px);
}

.panel-card > div > div > .timestamp {
  height: 20px;
  padding: 2px 8px;
  border: solid 1px white;
  border-radius: 4px;

  font-size: 12px;
}

.panel-card > div > div > .black {
  color: #000000;
}

.panel-card > div > div > .gray {
  color: var(--gray50);
}

.panel-card > div > div > .green {
  color: var(--success40);
}

.panel-card > div > div > .pink {
  color: #ffafd2;
}

.panel-card > div > div > .purple {
  color: #d4bbff;
}

.panel-card > div > div > .red {
  color: var(--urgent40);
}

.panel-card > div > div > .yellow {
  color: var(--warning40);
}

.panel-card .panel-header {
  display: flex;
  flex-direction: column;
}

.panel-inner {
  display: flex;
  flex-direction: column;
  width: 356px;
  height: 100%;
  padding: 12px 16px 12px 16px;

  background-color: var(--gray90);
}

.panel-list {
  height: 100%;
  padding-top: 10px;
  overflow: auto;
  flex-grow: 1;
}

.panel-ribbon {
  display: flex;
  justify-content: space-between;
  height: 32px;
}

.panel-ribbon > h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
}

.panel-handle {
  display: flex;
  align-items: center;
  width: 16px;
  height: 100%;

  cursor: pointer;
}

.panel-handle:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.panel-handle > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;

  background-color: var(--gray90);
}

#panels {
  display: flex;
  width: 100%;
  height: calc(100vh - var(--headerHeight) - var(--tutorialBarHeight));
}

#profile-dropdown {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;

  cursor: pointer;
}

#profile-dropdown > .card {
  position: absolute;
  top: 51px;
  right: 0;
}

#profile-dropdown > .card > a {
  color: var(--gray10);
}

#profile-picture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  padding: 1px;
  border-radius: 50%;
  overflow: hidden;

  background-color: var(--gray80);
}

.scrubber {
  width: 100%;
  height: 100%;
  position: relative;
  user-select: none;
  touch-action: none;
}

.scrubber .bar {
  position: relative;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 6px;
  border-radius: 3px;

  background-color: var(--gray80);
}

.scrubber .bar__progress {
  position: absolute;
  height: 100%;
  border-radius: 3px;

  background-color: white;
}

.scrubber .bar__tooltip {
  display: flex;
  align-items: center;
  position: absolute;
  height: 28px;
  padding: 0 8px;
  border-radius: 6px;

  background-color: white;
  color: #000000;
  transform: translate(-50%, calc(-100% - 10px));
  white-space: nowrap;
}

.scrubber .bar__thumb {
  position: absolute;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  top: 50%;

  background-color: white;
}

.scrubber.hover .bar__thumb {
  width: 18px;
  height: 18px;
}

#tutorial-bar {
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--tutorialBarHeight);
  padding: 0 16px;
}

#tutorial-bar > div {
  flex: 1;
}

.tutorial-step {
  display: flex;
  align-items: center;
}

.tutorial-step > .tutorial-step-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  margin-right: 24px;
  border: 2px solid var(--gray50);
  border-radius: 50%;

  color: var(--gray50);

  font-size: 20px;
  font-weight: 700;
}

.tutorial-step.in-progress > .tutorial-step-badge {
  border: 2px solid var(--primary60);

  color: var(--primary60);
}

.tutorial-step.finished > .tutorial-step-badge {
  border: 2px solid var(--primary60);

  background-color: var(--primary60);
  color: white;
}

.tutorial-step-text {
  width: 320px;
}

.tutorial-step-text > h2 {
  margin-bottom: 4px;

  font-size: 20px;
  font-weight: 600;
}

.tutorial-step-text > p {
  font-size: 16px;
}

.mapboxgl-ctrl-top-right {
  z-index: 0;
  margin-top: 8px;
  margin-right: 6px;
}

.tenant-name {
  display: none;
}

@media (prefers-reduced-motion) {
  .slider {
    transition: none;
  }
}

@media (width >=792px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .tenant-name {
    display: inline;
  }
}

@media (width >=1220px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
